import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
// import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";
// import '../assets/styles/responsive.css';
import '../assets/styles/style.css';

const IndexPage = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <section className="slider_section" style={{ backgroundImage: 'url(../../images/hero-bg.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', padding: '60px 0' }}>
        <div className="container text-white">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="detail-box">
                <h1 className="display-4">Ihr zuverlässiger Partner für Heizung und Sanitär!</h1>
                <p>Ob Neubauten, Renovierungen oder Wartungsarbeiten - maßgeschneiderte Lösungen im Bereich Heizung und Sanitärtechnik.</p>
                <button onClick={() => handleNavigation('/contact')} className="btn btn-warning btn-lg mt-3">
                  <i className="fa fa-phone"></i> Kontaktieren Sie uns!
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 text-center">
              <img src="../../images/logoMain.jpg" className="img-fluid" alt="Company Logo" style={{ maxWidth: '100%', marginTop: '20px' }} />
            </div>
          </div>
        </div>
      </section>

      <section className="services_section py-5" style={{ backgroundColor: '#f0f2f5' }}> {/* Light gray background for the section */}
  <div className="container text-center">
    <h2 className="mb-4">Unsere Dienstleistungen</h2>
    <div className="row">
      <div className="col-md-4">
        <div 
          className="service-card p-4 shadow" 
          onClick={() => handleNavigation('/services')} 
          style={{ 
            cursor: 'pointer', 
            backgroundColor: '#f7f9fc', // Light background color for the card
            transition: 'transform 0.3s, background-color 0.3s' 
          }}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#ff9800'} // Hover color matching button color
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f7f9fc'}
        >
          <img src="../../images/heater.png" alt="Heizung" className="mb-3" style={{ width: '50px' }} />
          <h5>HEIZUNG</h5>
          <p>Professionelle Heizsystemlösungen für Neubauten und Wartung.</p>
        </div>
      </div>
      
      <div className="col-md-4">
        <div 
          className="service-card p-4 shadow" 
          onClick={() => handleNavigation('/services')} 
          style={{ 
            cursor: 'pointer', 
            backgroundColor: '#f7f9fc', // Light background color for the card
            transition: 'transform 0.3s, background-color 0.3s' 
          }}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#ff9800'} // Hover color matching button color
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f7f9fc'}
        >
          <img src='../../images/s3.png' alt='Sanitär' className="mb-3" style={{ width: '50px' }} />
          <h5>Sanitär</h5>
          <p>Effiziente Sanitärlösungen für komfortables Wohnen.</p>
        </div>
      </div>
      
      <div className="col-md-4">
        <div 
          className="service-card p-4 shadow" 
          onClick={() => handleNavigation('/services')} 
          style={{ 
            cursor: 'pointer', 
            backgroundColor: '#f7f9fc', // Light background color for the card
            transition: 'transform 0.3s, background-color 0.3s' 
          }}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#ff9800'} // Hover color matching button color
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f7f9fc'}
        >
          <img src="../../images/s1.png" alt="Wartung" className="mb-3" style={{ width: '54px' }} />
          <h5>Wartung</h5>
          <p>Zuverlässige Wartung für eine lange Lebensdauer Ihrer Systeme.</p>
        </div>
      </div>
    </div>
  </div>
</section>




      <section className="about_section layout_padding-bottom py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div className="detail-box">
                <h2>Über uns</h2>
                <p>Firma Simnica ist Ihr Experte für die Wartung und Reparatur von Heizungs- und Sanitäranlagen.</p>
                <button onClick={() => handleNavigation('/about')} className="btn btn-primary">
                  <i className="fa fa-info-circle"></i> Erfahren Sie mehr
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default IndexPage;

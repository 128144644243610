import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import '../assets/styles/Services.css';

const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const handleShowImages = (service) => {
    setSelectedService(service);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  // Data for images related to each service
  const serviceImages = {
    heizung: [
      '../../images/Heating1.jpg',
      '../../images/Heating3.jpg',
      '../../images/Heating2.jpg'
    ],
    wasser: [
      '../../images/Waster1.jpg',
      '../../images/Wasser2.jpg',
      '../../images/Wasser1.jpg'
    ],
    wartung: [ 
      '../../images/heizungImage1.jpg',
      '../../images/Heating2.jpg',
      '../../images/heizungImage3.jpg'
    ],
  };

  return (
    <div>
      <div className="hero_area"></div>

      <section className="service_section layout_padding">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>Dienstleistungen</h2>
            <span>FIRMA SIMNICA</span>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-4 mx-auto">
              <div className="box" onClick={() => handleShowImages('heizung')}>
                <div className="img-box1">
                  <img src="images/heater.png" alt="Heizung" />
                </div>
                <div className="detail-box">
                  <h5>Heizung</h5>
                  <p>
                    Unser Heizungswartungsservice sorgt für optimale Leistung
                    und Langlebigkeit Ihres Heizungssystems. Wir führen
                    gründliche Inspektionen und Wartungsarbeiten durch, um
                    Ausfälle zu verhindern, die Effizienz zu steigern und
                    Energiekosten zu senken.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 mx-auto">
              <div className="box" onClick={() => handleShowImages('wasser')}>
                <div className="img-box1">
                  <img src="images/s3.png" alt="Wasser" />
                </div>
                <div className="detail-box">
                  <h5>Wasser</h5>
                  <p>
                    Unser Wassersystemservice bietet gründliche Wartung, um
                    sicherzustellen, dass Ihre Sanitär-und Wassersysteme
                    reibungslos und effizient funktionieren. Wir führen
                    detaillierte Inspektionen durch, ergreifen vorbeugende
                    Maßnahmen, um Probleme zu vermeiden, und minimieren
                    Reparaturkosten.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 mx-auto">
              <div className="box" onClick={() => handleShowImages('wartung')}>
                <div className="img-box1">
                  <img src="images/s1.png" alt="Wartung" />
                </div>
                <div className="detail-box">
                  <h5>Wartung</h5>
                  <p>
                    Unser Wartungsservice stellt sicher, dass alle Systeme
                    regelmäßig überprüft und gewartet werden, um die
                    Zuverlässigkeit und Langlebigkeit zu gewährleisten. Durch
                    präventive Wartungsmaßnahmen können potenzielle Probleme
                    frühzeitig erkannt und größere Reparaturen vermieden werden.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal to display images */}
      <Modal show={showModal} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title-center">Bilder für {selectedService === 'heizung' ? 'Heizung' : selectedService === 'wasser' ? 'Wasser' : 'Wartung'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {selectedService && serviceImages[selectedService].map((imgSrc, index) => (
              <div key={index} className="col-md-4">
                <img src={imgSrc} alt={selectedService} className="img-fluid" />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Services;

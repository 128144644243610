import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../assets/styles/responsive.css';
import '../assets/styles/style.css';

const About = () => {
  return (
    <div className="hero_area">
  <section className="about_section layout_padding">
    <div className="container">
      <div className="row align-items-center flex-column">
        {/* Image column - Will be above text on all screens */}
        <div className="col-12 mb-4">
          <img 
            src="../../images/logoMain.jpg" 
            className="img-fluid static-image" 
            alt="Company Logo"
          />
        </div>
        
        {/* Text column - Will be below the image */}
        <div className="col-lg-8 col-md-12">
          <div className="detail-box text-center">
            <h2>Über uns</h2>
            <p>
              Willkommen bei Firma SIMNICA - Ihrem zuverlässigen Partner für effiziente Heizlösungen! 
              Bei mir finden Sie die passende Lösung für Ihr Heizsystem. Firma Simnica besteht aus hochqualifizierten 
              Fachleuten, die sich leidenschaftlich für ihre Arbeit engagieren. Ich lege großen Wert auf individuelle Beratung 
              und maßgeschneiderte Lösungen, die genau auf Ihre Bedürfnisse abgestimmt sind.
              <br /><br />
              Kontaktieren Sie mich gerne für ein unverbindliches Beratungsgespräch. Ich freue mich darauf, Ihnen mit Rat und Tat 
              zur Seite zu stehen!
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  );
};

export default About;
